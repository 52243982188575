import React from 'react'
import { withRouter, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ReactGA from 'react-ga'

import './signup.module.css'
import Vdo from '../../Components/vdoplayer/Vdo'
import isEmpty from '../../validation/isEmpty'
import Form from '../../Components/Form/signupform'
import Navbar from '../../Components/Navbar/Navbar'
import { registerUser } from '../../actions/authActions.js'

import queryString from 'query-string'
import PageWrapper from '../../Components/PageWrapper/PageWrapper'

const bgvdo =
  'https://github.com/KSHITIJ-2022/media/blob/master/Signin-Signup-Video/video/scanner-loop-final1.mp4?raw=true'
const bgvdo1 =
  'https://github.com/KSHITIJ-2022/media/blob/master/Signin-Signup-Video/video-mobile/loop-mobile-final.mp4?raw=true'
// const bgvdogreen1 =
//   'https://github.com/KSHITIJ-2022/media/blob/master/Signin-Signup-Video/video-mobile/access-granted-final.mp4?raw=true'
// const bgvdogreen =
//   'https://github.com/KSHITIJ-2022/media/blob/master/Signin-Signup-Video/video/access-granted-final.mp4?raw=true'

// const bgvdored =
//   'https://github.com/KSHITIJ-2022/media/blob/master/Signin-Signup-Video/video/access-denied-final.mp4?raw=true'
// const bgvdored1 =
//   'https://github.com/KSHITIJ-2022/media/blob/master/Signin-Signup-Video/video-mobile/access-denied-final.mp4?raw=true'
class signup extends React.Component {
  constructor(props) {
    super()
    this.state = {
      errors: {},
      redirectToProfile: this.redirectToProfile.bind(this),
      link: window.innerWidth < 996 ? bgvdo1 : bgvdo,
      isTrue: true,
    }
  }
  componentDidMount() {
    window.addEventListener('resize', this.handleWindowSizeChange)
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange)
  }

  handleWindowSizeChange = () => {
    // this.setState({ isTrue: true })
    if (window.innerWidth < 996) {
      this.setState({
        link: bgvdo1,
      })
    } else {
      this.setState({
        link: bgvdo,
      })
    }
  }
  // handleVideoChange = () => {
  //   if (isEmpty(this.state.errors)) {
  //     this.setState({ isTrue: false })

  //     if (window.innerWidth < 996) {
  //       this.setState({ link: bgvdogreen1 })
  //     } else {
  //       this.setState({ link: bgvdogreen })
  //     }
  //   } else {
  //     this.setState({ isTrue: false })

  //     if (window.innerWidth < 996) {
  //       this.setState({ link: bgvdored1 })
  //     } else {
  //       this.setState({ link: bgvdored })
  //     }
  //   }
  // }

  handleQueryString = () => {
    // Parsing the query string
    // Using parse method
    let queries = queryString.parse(this.props.location.search)
    console.log(queries)
    const search = useLocation().search
    const code = new URLSearchParams(search).get('code')
    console.log(code)
    return code
  }

  redirectToProfile = () => {
    if (localStorage.getItem('PreviousPath')) {
      this.props.history.push(localStorage.getItem('PreviousPath'))
    } else {
      this.props.history.push('/profile')
    }
  }

  // Getting errors from backend and updating when new errors arrive
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.auth.isAuthenticated) {
      return {
        model: prevState.redirectToProfile(nextProps.model),
      }
    }
    if (nextProps.errors !== prevState.errors) {
      return { errors: nextProps.errors }
    } else return null
  }

  callAPI = async (e) => {
    e.preventDefault()

    const search = this.props.location.search
    const code = new URLSearchParams(search).get('code')

    const newUser = {
      username: document.getElementById('name').value.trim(),
      email: document.getElementById('email').value.trim(),
      gender: document.getElementById('gender').value.trim(),
      phone: document.getElementById('phone').value.trim(),
      college: document.getElementById('college').value.trim(),
      collegeid: document.getElementById('clgid').value.trim(),
      department: document.getElementById('dep').value.trim(),
      city: document.getElementById('city').value.trim(),
      state: document.getElementById('state').value.trim(),
      password: document.getElementById('password').value.trim(),
      conpassword: document.getElementById('conpassword').value.trim(),
    }
    console.log('before showing signupmessage')
    newUser.code = code
    await this.props.registerUser(newUser, this.props.history)
    ReactGA.event({
      category: 'Click',
      action: 'Clicked Sign Up button on Signup page',
    })
  }
  render() {
    const search = this.props.location.search
    const codeid = new URLSearchParams(search).get('code')
    const emailid = new URLSearchParams(search).get('email')
    return (
      <PageWrapper>
        {emailid ? (
          <>
            <Vdo
              name={this.state.link}
              loopCondition={this.state.isTrue}
              ended={this.handleWindowSizeChange}
            />
            <Form
              errors={this.state.errors}
              function={this.callAPI}
              querycode={codeid}
              queryemailid={emailid}
              loading={this.props.loading}
              // clicked={this.handleVideoChange}
            />
          </>
        ) : (
          this.props.history.goBack()
        )}
      </PageWrapper>
    )
  }
}

// Just checking the type of the props component
signup.propTypes = {
  registerUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  loading: state.auth.loading,
})

export default connect(mapStateToProps, { registerUser })(withRouter(signup))
