import classes from "./Footer.module.css";
import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookSquare,
  faInstagram,
  faLinkedin,
  faYoutube,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import ReactGA from "react-ga";

import { regForNewsletters } from "../../actions/authActions";

const closeFooterAndGAclick = (e) => {
  ReactGA.event({
    category: "Click",
    action: "Clicked " + e.target.innerText + " in Footer",
  });
  window.scroll({ left: 0, top: 0, behavior: "smooth" });
};

const Footer = () => (
  <div className={classes.Footer} id="footer">
    {/* <div className={classes.Homepagelinks}>
      <div>
        <Link onClick={closeFooterAndGAclick} to="/home/about">
          About Us
        </Link>
      </div>
      <div>
        <Link onClick={closeFooterAndGAclick} to="/home/growth">
          Growth
        </Link>
      </div>
      <div>
        <Link onClick={closeFooterAndGAclick} to="/home/theme">
          Theme
        </Link>
      </div>
      <div>
        <Link onClick={closeFooterAndGAclick} to="/home/initiatives">
          Initiatives
        </Link>
      </div>
      <div>
        <Link onClick={closeFooterAndGAclick} to="/home/sponsors">
          Sponsors
        </Link>
      </div>
    </div> */}
    <div className={classes.Otherlinks}>
      <div className={classes.footerLogo}>
        <div className={classes.footerImgDivs}>
          <Link onClick={closeFooterAndGAclick} to="/">
            <div className={classes.title}>
              <img
                src="https://github.com/KSHITIJ-2022/media/blob/master/images/ILU-light-new.png?raw=true"
                alt="Kshitij 2021"
              />
            </div>
          </Link>
        </div>
        <div className={classes.footerImgDivs}>
          <p>
            Kshitij, IIT Kharagpur's annual techno-management symposium, has
            grown in popularity, celebrating the spirit of science and
            technology by bringing together students from all across India to
            demonstrate their scientific and managerial prowess.
            {/* </p>
        <p> */}
            <br />
            We're renowned for putting on an eclectic mix of events, including
            synchronized workshops, technical displays, and guest talks by
            experts in the fields of technology and entrepreneurship. Students
            can assess their abilities and set greater goals than ever before
            thanks to events ranging from technology to management.
          </p>
        </div>
      </div>

      <div className={classes.footerColumns}>
        <div className={[classes.headingBox].join(" ")}>Sitemap</div>
        <div className={[classes.headingBox].join(" ")}>Activities</div>
        <div className={[classes.headingBox].join(" ")}>Events</div>
        <div className={[classes.headingBox, classes.contactUs].join(" ")}>
          <Link
            className={classes.contactUs}
            onClick={closeFooterAndGAclick}
            to="/contactus"
          >
            Contact Us
          </Link>
        </div>
        <div className={[classes.box].join(" ")}>
          <Link onClick={closeFooterAndGAclick} to="/home/events">
            Events
          </Link>
        </div>
        <div className={[classes.box].join(" ")}>
          <Link onClick={closeFooterAndGAclick} to="/activities/exhibitions">
            Exhibitions
          </Link>
        </div>
        <div className={[classes.box].join(" ")}>
          <Link onClick={closeFooterAndGAclick} to="/events/competitions">
            Competitions
          </Link>
        </div>
        <div className={[classes.box].join(" ")}>
          <Link onClick={closeFooterAndGAclick} to="/contactus/core">
            Core Team
          </Link>
        </div>
        <div className={[classes.box].join(" ")}>
          <Link onClick={closeFooterAndGAclick} to="/home/activities">
            Activities
          </Link>
        </div>
        <div className={[classes.box].join(" ")}>
          <Link onClick={closeFooterAndGAclick} to="/activities/guestlectures">
            Guest Lectures
          </Link>
        </div>
        <div className={[classes.box].join(" ")}>
          <Link onClick={closeFooterAndGAclick} to="/events/gamefest">
            Gamefest
          </Link>
        </div>
        <div className={[classes.box].join(" ")}>
          <Link onClick={closeFooterAndGAclick} to="/contactus/design">
            Design Team
          </Link>
        </div>
        <div className={[classes.box].join(" ")}>
          <Link onClick={closeFooterAndGAclick} to="/home/theme">
            Theme
          </Link>
        </div>
        <div className={[classes.box].join(" ")}>
          <Link onClick={closeFooterAndGAclick} to="/activities/interactiveSession">
            Interactive Sessions
          </Link>
        </div>
        <div className={[classes.specialBox].join(" ")}>
          <span className={[classes.innoCorner].join(" ")}>
            <Link onClick={closeFooterAndGAclick} to="/innovative">
              Innovative Corner
            </Link>
          </span>
        </div>
        <div className={[classes.sponsBox, classes.box].join(" ")}>
          <Link onClick={closeFooterAndGAclick} to="/sponsors">
            Sponsors
          </Link>
        </div>
        <div className={[classes.box].join(" ")}>
          <Link onClick={closeFooterAndGAclick} to="/contactus/tech">
            Tech Team
          </Link>
        </div>
        <div className={[classes.box].join(" ")}>
          <Link onClick={closeFooterAndGAclick} to="/home/initiatives">
            Initiatives
          </Link>
        </div>
        <div className={[classes.box].join(" ")}>
          <Link onClick={closeFooterAndGAclick} to="/activities/summits">
            Summits
          </Link>
        </div>

        <div className={[classes.emptyBox].join(" ")}>box16</div>
        <div className={[classes.box].join(" ")}>
          <Link onClick={closeFooterAndGAclick} to="/home/sponsors">
            Sponsors
          </Link>
        </div>
        <div className={[classes.box].join(" ")}>
          <Link onClick={closeFooterAndGAclick} to="/activities/workshops">
            Workshops
          </Link>
        </div>
        {/* <div className={[classes.specialBox,classes.sponsBox].join(' ')}>Sponsors</div> */}
        <div className={[classes.emptyBox].join(" ")}>box24</div>
      </div>
      {/* Mobile Version Start */}
      <div className={[classes.mobileFooter, classes.mobile].join(" ")}>
        <div className={[classes.mobileFooterG1].join(" ")}>
          <div
            className={[
              classes.headingBox,
              classes.mobile,
              classes.sitemapHeading,
            ].join(" ")}
          >
            Sitemap
          </div>
          <div className={[classes.box, classes.mobile].join(" ")}>
            <Link onClick={closeFooterAndGAclick} to="/home/events">
              Events
            </Link>
          </div>
          <div className={[classes.box, classes.mobile].join(" ")}>
            <Link onClick={closeFooterAndGAclick} to="/home/activities">
              Activities
            </Link>
          </div>
          <div className={[classes.box, classes.mobile].join(" ")}>
            <Link onClick={closeFooterAndGAclick} to="/home/theme">
              Theme
            </Link>
          </div>
          <div className={[classes.box, classes.mobile].join(" ")}>
            <Link onClick={closeFooterAndGAclick} to="/home/initiatives">
              Initiatives
            </Link>
          </div>
          <div className={[classes.box, classes.mobile].join(" ")}>
            <Link onClick={closeFooterAndGAclick} to="/home/sponsors">
              Sponsors
            </Link>
          </div>
        </div>
        {/* <div className={[classes.emptyBox,classes.mobile].join(" ")}>box16</div> */}
        <div className={[classes.mobileFooterG2].join(" ")}>
          <div
            className={[
              classes.headingBox,
              classes.mobile,
              classes.eventsHeading,
            ].join(" ")}
          >
            Events
          </div>
          <div className={[classes.box, classes.mobile].join(" ")}>
            <Link onClick={closeFooterAndGAclick} to="/events/competitions">
              Competitions
            </Link>
          </div>
          <div className={[classes.box, classes.mobile].join(" ")}>
            <Link onClick={closeFooterAndGAclick} to="/events/gamefest">
              Gamefest
            </Link>
          </div>
        </div>
        <div className={[classes.mobileFooterG3].join(" ")}>
          <div
            className={[
              classes.headingBox,
              classes.mobile,
              classes.activitiesHeading,
            ].join(" ")}
          >
            Activities
          </div>
          <div className={[classes.box, classes.mobile].join(" ")}>
            <Link onClick={closeFooterAndGAclick} to="/activities/exhibitions">
              Exhibitions
            </Link>
          </div>
          <div className={[classes.box, classes.mobile].join(" ")}>
            <Link onClick={closeFooterAndGAclick} to="/activities/summits">
              Summits
            </Link>
          </div>

          <div
            className={[classes.box, classes.mobile, classes.doubleLines].join(
              " "
            )}
          >
            <Link
              onClick={closeFooterAndGAclick}
              to="/events/interactiveSession"
            >
              Interactive Sessions
            </Link>
          </div>
          <div
            className={[classes.box, classes.mobile, classes.doubleLines].join(
              " "
            )}
          >
            <Link
              onClick={closeFooterAndGAclick}
              to="/activities/guestlectures"
            >
              Guest Lectures
            </Link>
          </div>
          <div className={[classes.box, classes.mobile].join(" ")}>
            <Link onClick={closeFooterAndGAclick} to="/activities/workshops">
              Workshops
            </Link>
          </div>
          <div className={[classes.emptyBox, classes.mobile].join(" ")}>
            box16
          </div>
        </div>
        <div className={[classes.mobileFooterG4].join(" ")}>
          <div
            className={[
              classes.specialBox,
              classes.mobile,
              classes.headingBox,
            ].join(" ")}
          >
            <span className={[classes.innoCorner].join(" ")}>
              <Link onClick={closeFooterAndGAclick} to="/innovative">
                Innovative Corner
              </Link>
            </span>
          </div>

          <div
            className={[
              classes.headingBox,
              classes.mobile,
              classes.contactUs,
              classes.mobileContactUs,
            ].join(" ")}
          >
            <Link
              className={classes.contactUs}
              onClick={closeFooterAndGAclick}
              to="/contactus"
            >
              Contact Us
            </Link>
          </div>
        </div>
      </div>

      {/* Mobile Version End */}
    </div>
    <div className={classes.thirddiv}>
      <div className={classes.letsconnect}>
        <p className={classes.onlyDesktop} style={{ color: "white" }}>
          Follow Us
        </p>
        <div className={classes.connectlinks}>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.facebook.com/ktj.iitkgp"
            onClick={() => {
              ReactGA.event({
                category: "Click",
                action: "Clicked Facebook Logo in Footer",
              });
            }}
          >
            <FontAwesomeIcon icon={faFacebookSquare} />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.instagram.com/ktj.iitkgp"
            onClick={() => {
              ReactGA.event({
                category: "Click",
                action: "Clicked Instagram Logo in Footer",
              });
            }}
          >
            <FontAwesomeIcon icon={faInstagram} />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://youtube.com/user/ktjiitkgp"
            onClick={() => {
              ReactGA.event({
                category: "Click",
                action: "Clicked Youtube Logo in Footer",
              });
            }}
          >
            <FontAwesomeIcon icon={faYoutube} />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://twitter.com/ktj_iitkgp"
            onClick={() => {
              ReactGA.event({
                category: "Click",
                action: "Clicked Twitter Logo in Footer",
              });
            }}
          >
            <FontAwesomeIcon icon={faTwitter} />
          </a>

          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.linkedin.com/company/kshitij-iit-kharagpur"
            onClick={() => {
              ReactGA.event({
                category: "Click",
                action: "Clicked LinkedIn Logo in Footer",
              });
            }}
          >
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
        </div>
      </div>
      <div className={classes.register}>
        <div className={classes.letsconnect}>
          <label htmlFor="email">
            <p style={{ color: "white" }}>Subscribe for Newsletters:</p>
          </label>
          <div className={classes.footerEmail}>
            <input
              type="email"
              name="email"
              placeholder="Your Email"
              id="footer-email"
            />
            <input
              type="submit"
              style={{ flex: ".2", color: "inherit" }}
              onClick={() => {
                regForNewsletters(
                  document.getElementById("footer-email").value
                );
              }}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Footer;
