import React, { useState, useEffect } from 'react'
import classes from './Sponsors.module.css'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import api from '../../../api'
export default function Sponsors() {
    const imgurl = [
      "https://i.imgur.com/Ea4CysT.png",
      "https://i.imgur.com/8tKxxeP.jpg",
      "https://i.imgur.com/B98o9IH.jpg",
      "https://i.imgur.com/XeI19s4.jpg",
      "https://i.imgur.com/XeYbdxa.jpg",
    ];
    const imglist = [imgurl, imgurl, imgurl, imgurl, imgurl, imgurl]
    const [sponsors, setSponsors] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        console.log("Sponsors")
        api.get('/sponsors', { params: { year: 2022 } }).then(res => {
            console.log("Sponsors ", res.data.sponsors[0].sponsors.sponsorId)
            setSponsors(res.data.sponsors)
        })
    }, [])

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 5,
        },
        desktop: {
            breakpoint: { max: 3000, min: 768 },
            items: 3,
        },
        mobile: {
            breakpoint: { max: 768, min: 0 },
            items: 2,
        },
    }

    return (
      <div>
        <div className={classes.row}>
          {/* {loading && <svg style={{ width: '10vw' }} version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    viewBox="0 0 100 100" enable-background="new 0 0 0 0" xmlSpace="preserve">
                    <path fill="#fff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                        <animateTransform
                            attributeName="transform"
                            attributeType="XML"
                            type="rotate"
                            dur="1s"
                            from="0 50 50"
                            to="360 50 50"
                            repeatCount="indefinite" />
                    </path>
                </svg>}
                <img onLoad={() => {
                    setLoading(false);
                }} style={{ width: "250px", height: "150px" }} src={sponsors[0]?.sponsors.sponsorId} /> */}
          <img
            onLoad={() => {
              setLoading(false);
            }}
            style={{ width: "250px", height: "150px" }}
            src={
              "https://aceupdate.com/wp-content/uploads/2021/09/TATA-Project.jpg"
            }
          />
        </div>
        <div className={classes.row}>
          <img
            style={{
              height: "120px",
              width: "200px",
              marginRight: "20px",
              objectFit: "contain",
            }}
            src={"https://i.imgur.com/hqB7xeF.jpg"}
          />
          <img
            style={{ height: "120px", width: "200px", objectFit: "contain" }}
            src={"https://i.imgur.com/rAi3o6r.jpg"}
          />
        </div>

        <div className={classes.slide}>
          <Carousel
            responsive={responsive}
            infinite
            focusOnSelect
            autoPlay
            autoPlaySpeed={1500}
            // customDot={CustomDot}
            showDots
            swipeable
            containerClass={classes.slide}
          >
            {imgurl.map((e, i) => {
              // if (i % 2 == 0) {
              //     return (
              //         <div className={classes.frames} key={i}>
              //             <img className={classes.bImg} style={{ height: "120px", width: "200px" }} src={e} />
              //         </div>

              //     )
              // }
              return (
                <div className={classes.frames} key={i}>
                  <img className={classes.bImg} src={e} />
                </div>
              );
            })}
          </Carousel>
        </div>
      </div>
    );
}
