import React, { useState, useRef, useEffect } from 'react'
import { NavLink, withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import ReactGa from 'react-ga'
import axios from '../../api'
import { BsFillBellFill } from 'react-icons/bs'
import classes from './NavBar.module.css'
import { logoutUser } from '../../actions/authActions'
import { useHistory } from 'react-router-dom'
import Backdrop from '@material-ui/core/Backdrop'
import { format } from "timeago.js";
// import UseComponentVisible from "../../Containers/Homepage/UseComponentVisible/UseComponentVisible"

// import arrow from "https://github.com/KSHITIJ-2022/media/blob/master/images/navbar/arrow.png?raw=true";

function NavBar(props) {
  const [data, setData] = useState([]);
  const [drop1, setDrop1] = useState(false);
  const [drop2, setDrop2] = useState(false);
  const [drop3, setDrop3] = useState(false);
  const [drop4, setDrop4] = useState(false);
  const [isActive, setActive] = useState(false);
  const [isActive2, setActive2] = useState(false);
  const [width, setWidth] = useState(0);
  const fetchEventData = () => {
    axios
      .get("/notification/")
      .then((result) => {
        let list = [];
        result.data.notifications.map((res) => {
          list.push(res);
        });
        list.sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt));
        setData(list);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    fetchEventData();
    document
      .getElementById("notifscrolldiv")
      .addEventListener("wheel", (e) => e.stopPropagation());
  }, []);
  const toggleClass = () => {
    setActive(!isActive);
  };
  const toggleClass1 = () => {
    setActive2(!isActive2);
    if (width == 300) {
      setWidth(0);
    } else {
      setWidth(300);
    }
  };

  // google analytics
  const google_analy = (x) => {
    ReactGa.event({
      category: "Click",
      action: x,
    });
  };

  // linking in div
  const history = useHistory();
  function handleClick(x, y) {
    history.push(x);
    google_analy(y);
  }

  return (
    <>
      <div className={classes.NBar} id="navbar">
        <Backdrop
          open={width == 300}
          className={classes.backdrop}
          onClick={() => {
            setWidth(0);
            toggleClass1();
          }}
        ></Backdrop>
        <div className={classes.NavLogo}>
          <NavLink
            onClick={() => {
              window.scroll({ left: 0, top: 0, behavior: "smooth" });
              google_analy("Clicked on Navbar Logo");
            }}
            to="/home"
            style={{ color: " #c4b9e2", textDecoration: "none" }}
          >
            <img
              src="https://github.com/KSHITIJ-2022/media/blob/master/images/ILU-light-new.png?raw=true"
              height="100%"
              alt="Kshitij"
            />
          </NavLink>
        </div>
        <div className={classes.Navlinks}>
          <ul>
            {props.auth?.user.userType == "superAdmin" ||
            props.auth?.user.userType == "admin" ? (
              <li>
                <Link
                  onClick={() => {
                    window.scroll({ left: 0, top: 0, behavior: "smooth" });
                    google_analy("Clicked on Admin-panel in the navbar");
                    window.scroll({ left: 0, top: 0, behavior: "smooth" });
                  }}
                  to="/admin-panelktj2022"
                  exact
                >
                  <div className={classes.linkboxinner}>Admin</div>
                </Link>
              </li>
            ) : null}

            <li>
              <div className={classes.sbox_outer}>
                <Link
                  to="/schedule"
                  onClick={() => {
                    google_analy("Clicked on Workshop Tab");
                  }}
                >
                  <div className={classes.linkboxinner}>Schedule</div>
                </Link>
              </div>
            </li>
            <li></li>
            <li>
              <div className={classes.sbox_outer}>
                <Link onClick={(e) => e.preventDefault()}>
                  <div className={classes.linkboxinner}>Events</div>
                </Link>
                {/* <Link
                  to='/events/'
                  onClick={() => {
                    google_analy('Clicked on Competitions Tab')
                  }}
                >
                  <div className={classes.linkboxinner}>Events</div>
                </Link> */}
                <div className={classes.sbox}>
                  <div
                    onClick={() =>
                      handleClick(
                        "/events/competitions",
                        "Clicked on competitions in Navbar"
                      )
                    }
                    className={classes.stext}
                  >
                    <Link className={classes.slink} to="/events/competitions">
                      Competitions
                    </Link>
                  </div>

                  <div
                    onClick={() =>
                      handleClick(
                        "/events/gamefest",
                        "Clicked on Gamefest in Navbar"
                      )
                    }
                    className={classes.stext}
                  >
                    <Link className={classes.slink} to="/events/gamefest">
                      Gamefest
                    </Link>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className={classes.sbox_outer}>
                <Link
                  to="/activities/workshops"
                  onClick={() => {
                    google_analy("Clicked on Workshop Tab");
                  }}
                >
                  <div className={classes.linkboxinner}>Workshops</div>
                </Link>
              </div>
            </li>
            <li>
              <div className={classes.sbox_outer}>
                <Link onClick={(e) => e.preventDefault()}>
                  <div className={classes.linkboxinner}>Activities</div>
                </Link>
                <div className={classes.sbox}>
                  <div
                    onClick={() =>
                      handleClick(
                        "/activities/guestlectures",
                        "Clicked on Guest Lectures in Navbar"
                      )
                    }
                    className={classes.stext}
                  >
                    <Link
                      className={classes.slink}
                      to="/activities/guestlectures"
                    >
                      Guest Lectures
                    </Link>
                  </div>

                  <div
                    onClick={() =>
                      handleClick(
                        "/activities/exhibitions",
                        "Clicked on Exhibitions in Navbar"
                      )
                    }
                    className={classes.stext}
                  >
                    <Link
                      className={classes.slink}
                      to="/activities/exhibitions"
                    >
                      Exhibitions
                    </Link>
                  </div>

                  <div
                    onClick={() =>
                      handleClick(
                        "/activities/interactiveSession",
                        "Clicked on Interactive Sessions in Navbar"
                      )
                    }
                    className={classes.stext}
                  >
                    <Link className={classes.slink} to="/coming">
                      Interactive Sessions
                    </Link>
                  </div>

                  <div
                    onClick={() =>
                      handleClick(
                        "/activities/summits",
                        "Clicked on Summits in Navbar"
                      )
                    }
                    className={classes.stext}
                  >
                    <Link className={classes.slink} to="/activities/summits">
                      Summits
                    </Link>
                  </div>
                  <div
                    onClick={() =>
                      handleClick(
                        "/activities/leadershiptalks",
                        "Clicked on Leadership Talks in Navbar"
                      )
                    }
                    className={classes.stext}
                  >
                    <Link
                      className={classes.slink}
                      to="/activities/leadershiptalks"
                    >
                      Leadership talks
                    </Link>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className={classes.sbox_outer}>
                <Link
                  to="/sponsors"
                  onClick={() => {
                    google_analy("Clicked on Sponsors Tab");
                  }}
                >
                  <div className={classes.linkboxinner}>Sponsors</div>
                </Link>
                {/* <div className={classes.sbox}>

                <div onClick={()=>handleClick("/coming","Clicked on Lorem, ipsum in Navbar")} className={classes.stext}><Link className = {classes.slink}
                to="/coming"> Lorem, ipsum</Link></div>

                </div> */}
              </div>
            </li>
            <li>
              <div className={classes.sbox_outer}>
                <Link
                  to="/contactus"
                  onClick={() => {
                    google_analy("Clicked on Contact Us Tab");
                  }}
                >
                  <div className={classes.linkboxinner}>Contact Us</div>
                </Link>
                {/* <div className={classes.sbox}>
                            
                  <div onClick={()=>handleClick("/coming","Clicked on Core Team in Navbar")} className={classes.stext}><Link className={classes.slink}
                    to="/coming">Core Team</Link></div>
        
                  <div onClick={()=>handleClick("/coming","Clicked on Design Team in Navbar")} className={classes.stext}><Link className = {classes.slink}
                to="/coming" >Design Team</Link></div>
            
                  <div onClick={()=>handleClick("/coming","Clicked on Web Team in Navbar")} className={classes.stext}><Link className = {classes.slink}
                to="/coming"> Web Team</Link></div>
    
                  <div onClick={()=>handleClick("/coming","Clicked on Innovative Corner in Navbar")} className={classes.stext}><Link className = {classes.slink}
                to="/coming"> Innovative Corner</Link></div>
                </div> */}
              </div>
            </li>
            <li>
              <div className={classes.sbox_outer}>
                <Link
                  to="/innovative"
                  onClick={() => {
                    google_analy("Clicked on Innovative Corner Tab");
                  }}
                >
                  <div className={classes.linkboxinner}>Innovative Corner</div>
                </Link>
              </div>
            </li>
            <li className={classes.notifications}>
              <span className={classes.notification}>
                <div
                  style={{ zIndex: "999" }}
                  onClick={toggleClass1}
                  className={
                    isActive2
                      ? `${classes.hamburger1} ${classes.active}`
                      : `${classes.hamburger1}`
                  }
                >
                  <BsFillBellFill
                    style={{
                      color: "white",
                      height: "23px",
                      width: "23px",
                      cursor: "pointer",
                      marginTop: "2px",
                    }}
                  />
                </div>
              </span>
            </li>
            {!props.isAuthenticated ? (
              <li className={classes.signInUp}>
                <span className={classes.Sinin}>
                  <Link
                    to="/signin"
                    onClick={() => {
                      google_analy("Clicked on Sign in");
                    }}
                  >
                    Sign In
                  </Link>
                </span>
                <span className={classes.Sinup}>
                  <Link
                    to="/signuppre"
                    onClick={() => {
                      google_analy("Clicked on Sign up");
                    }}
                  >
                    Sign Up
                  </Link>
                </span>
              </li>
            ) : (
              <li className={classes.signInUp}>
                <span className={classes.Sinin}>
                  <Link
                    to="/profile"
                    onClick={() => {
                      google_analy("Clicked on Profile");
                    }}
                  >
                    Profile
                  </Link>
                </span>
                <span className={classes.Sinup}>
                  <Link
                    to="/"
                    onClick={(e) => {
                      e.preventDefault();
                      props.logoutUser(
                        props.userInfo,
                        props.history.push("/signin")
                      );
                      google_analy("Clicked on Logout");
                    }}
                  >
                    Log Out
                  </Link>
                </span>
              </li>
            )}
          </ul>
          <ul
            className={
              isActive2
                ? `${classes.NavMenu1} ${classes.active}`
                : `${classes.NavMenu1}`
            }
          >
            <div
              style={{ width: `${width}px` }}
              className={`${classes.gradientdiv2}`}
            >
              <div
                style={{
                  backgroundImage:
                    "linear-gradient(to bottom right,rgba(255, 255, 255, 0.3),transparent)",
                  backgroundColor: "rgba(1, 4, 54, 1)",
                  height: "90vh",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div className={`${classes.navback}`}>
                  <img
                    src="https://github.com/KSHITIJ-2022/media/blob/master/images/ILU-light-new.png?raw=true"
                    height="85px"
                    width="75%"
                    alt="Kshitij"
                    style={{ marginLeft: "-35px" }}
                  />
                  <img
                    id="img"
                    src="https://github.com/KSHITIJ-2022/media/blob/master/images/navbar/arrow.png?raw=true"
                    onClick={() => {
                      setWidth(0);
                      toggleClass1();
                    }}
                    alt="&gt;"
                    style={{ width: "40px", marginRight: "1rem" }}
                  />
                </div>
                <li
                  className={classes.MobNavItem}
                  style={{ overflowY: "scroll" }}
                  id="notifscrolldiv"
                >
                  <div
                    onClick={(e) => e.preventDefault()}
                    className={
                      drop1 === true
                        ? `${classes.HamOuterStyle1}`
                        : `${classes.HamOuter1}`
                    }
                  >
                    <div
                      className={classes.notification_bar}
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                        minHeight: "100%",
                        width: "100%",
                      }}
                    >
                      {data.map((notification, index) => {
                        console.log(notification);
                        return (
                          <div
                            className={classes.notify_box}
                            onClick={() =>
                              window.open(notification.redirectUrl, "_blank")
                            }
                          >
                            <div className={classes.notify_subheader}>
                              <div className={classes.notify_header}>
                                {notification.title}
                              </div>
                              <div>{notification.message}</div>
                              <div className={classes.notify_time}>
                                {format(notification.createdAt)}
                              </div>
                            </div>
                            {/* <div className={classes.notify_imagebox}>
                              <img
                                className={classes.notify_image}
                                src={notification.imageUrl}
                              />
                            </div> */}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </li>
              </div>
            </div>
          </ul>
        </div>
      </div>

      <header className={classes.header}>
        <div
          className={
            isActive ? `${classes.tmp} ${classes.act}` : `${classes.tmp}`
          }
          onClick={() => {
            toggleClass();
          }}
        ></div>
        <nav className={classes.navbar}>
          <ul
            className={
              isActive
                ? `${classes.NavMenu} ${classes.active}`
                : `${classes.NavMenu}`
            }
          >
            <div className={classes.gradientdiv}>
              <div className={`${classes.navback}`}>
                <img
                  src="https://github.com/KSHITIJ-2022/media/blob/master/images/navbar/arrow.png?raw=true"
                  onClick={toggleClass}
                  alt="&gt;"
                  style={{ width: "50px", marginRight: "1.5rem" }}
                />
              </div>
              <li onClick={toggleClass}>
                <Link
                  onClick={() => {
                    window.scroll({ left: 0, top: 0, behavior: "smooth" });
                  }}
                  to="/home"
                >
                  <img
                    src="https://github.com/KSHITIJ-2022/media/blob/master/images/ILU-light-new.png?raw=true"
                    height="150px"
                    alt="Kshitij"
                  />
                </Link>
              </li>

              {/* {props.auth?.user.userType == "superAdmin" ||
              props.auth?.user.userType == "admin" ? (
                <li onClick={toggleClass}>
                  <Link
                    className={classes.NavItem}
                    onClick={() => {
                      window.scroll({ left: 0, top: 0, behavior: "smooth" });
                    }}
                    to="/admin-panelktj2022"
                    exact
                  >
                    Admin-Panel
                  </Link>
                </li>
              ) : null} */}

              {/* ------------------------------------------------- */}
              {/* <li className={classes.MobNavItem}>
                <div
                  onClick={(e) => e.preventDefault()}
                  className={
                    drop1 === true
                      ? `${classes.HamOuterStyle}`
                      : `${classes.HamOuter}`
                  }
                >
                  <div
                    className={
                      drop1 === true
                        ? `${classes.HamMasGlow}`
                        : `${classes.HamMas}`
                    }
                    onClick={() => {
                      setDrop1(!drop1);
                    }}
                  >
                    Events
                    <img
                      className={
                        drop1 === true ? `${classes.rot}` : `${classes.ori}`
                      }
                      src="https://github.com/KSHITIJ-2022/media/blob/master/images/homepage/Drop%20down.png?raw=true"
                      alt=""
                    />
                  </div>
                  <div
                    className={
                      drop1 === true
                        ? `${classes.HamVisi}`
                        : `${classes.HamInvisi}`
                    }
                  >
                    <div
                      className={classes.HamDrop}
                      onClick={() => {
                        handleClick(
                          "/events/",
                          "Clicked on Competitions in Mobile Navbar"
                        );
                        toggleClass();
                      }}
                    >
                      {" "}
                      Competitions{" "}
                    </div>
                    <div
                      className={classes.HamDrop}
                      onClick={() => {
                        handleClick(
                          "/coming",
                          "Clicked on Gamefest in Mobile Navbar"
                        );
                        toggleClass();
                      }}
                    >
                      Gamefest
                    </div>
                  </div>
                </div>
              </li> */}
              {/* ------------------------------------------------- */}
              <li className={classes.NavItem_com} onClick={toggleClass}>
                <Link
                  to="/schedule"
                  onClick={() => {
                    google_analy("Clicked on Sponsors Tab");
                  }}
                >
                  Schedule
                </Link>
              </li>
              <li className={classes.MobNavItem}>
                <div
                  onClick={(e) => e.preventDefault()}
                  className={
                    drop3 === true
                      ? `${classes.HamOuterStyle}`
                      : `${classes.HamOuter}`
                  }
                >
                  <div
                    className={
                      drop3 === true
                        ? `${classes.HamMasGlow}`
                        : `${classes.HamMas2}`
                    }
                    onClick={() => {
                      setDrop3(!drop3);
                    }}
                  >
                    Events
                    <img
                      className={
                        drop3 === true ? `${classes.rot}` : `${classes.ori}`
                      }
                      src="https://github.com/KSHITIJ-2022/media/blob/master/images/homepage/Drop%20down.png?raw=true"
                      alt=""
                    />
                  </div>
                  <div
                    className={
                      drop3 === true
                        ? `${classes.HamVisi}`
                        : `${classes.HamInvisi}`
                    }
                  >
                    <div
                      to="/events/competitions"
                      className={classes.HamDrop}
                      onClick={() => {
                        handleClick(
                          "/events/competitions",
                          "Clicked on Comepitions in Mobile Navbar"
                        );
                        toggleClass();
                      }}
                    >
                      Competitions
                    </div>
                    <div
                      to="/events/gamefest"
                      className={classes.HamDrop}
                      onClick={() => {
                        handleClick(
                          "/events/gamefest",
                          "Clicked on Gamefest in Mobile Navbar"
                        );
                        toggleClass();
                      }}
                    >
                      Gamefest
                    </div>
                  </div>
                </div>
              </li>
              <li className={classes.NavItem_com} onClick={toggleClass}>
                <Link
                  to="/activities/workshops"
                  onClick={() => {
                    google_analy("Clicked on Workshop in Mobile Navbar");
                  }}
                >
                  Workshops
                </Link>
              </li>
              <li className={classes.MobNavItem}>
                <div
                  onClick={(e) => e.preventDefault()}
                  className={
                    drop2 === true
                      ? `${classes.HamOuterStyle}`
                      : `${classes.HamOuter}`
                  }
                >
                  <div
                    className={
                      drop2 === true
                        ? `${classes.HamMasGlow}`
                        : `${classes.HamMas2}`
                    }
                    onClick={() => {
                      setDrop2(!drop2);
                    }}
                  >
                    Activities
                    <img
                      className={
                        drop2 === true ? `${classes.rot}` : `${classes.ori}`
                      }
                      src="https://github.com/KSHITIJ-2022/media/blob/master/images/homepage/Drop%20down.png?raw=true"
                      alt=""
                    />
                  </div>
                  <div
                    className={
                      drop2 === true
                        ? `${classes.HamVisi}`
                        : `${classes.HamInvisi}`
                    }
                  >
                    <div
                      to="/activities/exhibitions"
                      className={classes.HamDrop}
                      onClick={() => {
                        handleClick(
                          "/activities/exhibitions",
                          "Clicked on Exhibition in Mobile Navbar"
                        );
                        toggleClass();
                      }}
                    >
                      Exhibitions
                    </div>
                    <div
                      to="/activities/guestlectures"
                      className={classes.HamDrop}
                      onClick={() => {
                        handleClick(
                          "/activities/guestlectures",
                          "Clicked on Guest Lecture in Mobile Navbar"
                        );
                        toggleClass();
                      }}
                    >
                      Guest Lectures
                    </div>
                    <div
                      to="/activities/summits"
                      className={classes.HamDrop}
                      onClick={() => {
                        handleClick(
                          "/activities/summits",
                          "Clicked on Summits in Mobile Navbar"
                        );
                        toggleClass();
                      }}
                    >
                      Summits
                    </div>
                    <div
                      className={classes.HamDrop}
                      onClick={() => {
                        handleClick(
                          "/activities/interactiveSession",
                          "Clicked on Interactive Session in Mobile Navbar"
                        );
                        toggleClass();
                      }}
                    >
                      Interactive Sessions
                    </div>
                    <div
                      to="/activities/leadershiptalks"
                      className={classes.HamDrop}
                      onClick={() => {
                        handleClick(
                          "/activities/leadershiptalks",
                          "Clicked on Leadership Talks in Mobile Navbar"
                        );
                        toggleClass();
                      }}
                    >
                      Leadership talks
                    </div>
                  </div>
                </div>
              </li>
              <li className={classes.NavItem} onClick={toggleClass}>
                <Link
                  to="/sponsors"
                  onClick={() => {
                    google_analy("Clicked on Sponsors Tab");
                  }}
                >
                  Sponsors
                </Link>
              </li>
              <li className={classes.NavItem} onClick={toggleClass}>
                <Link
                  to="/innovative"
                  onClick={() => {
                    google_analy("Clicked on Innovative Corner Tab");
                  }}
                >
                  Innovative Corner
                </Link>
              </li>
              <li className={classes.NavItem} onClick={toggleClass}>
                <Link
                  to="/contactus"
                  onClick={() => {
                    google_analy("Clicked on Contact us Tab");
                  }}
                >
                  Contact Us
                </Link>
              </li>
              <div className={classes.spacer}></div>
              <li onClick={toggleClass}>
                {!props.isAuthenticated && (
                  <div className={classes.siginsignup}>
                    {" "}
                    <div className={classes.whitebdr}>
                      <Link to="/signin"> SignIn </Link>{" "}
                    </div>{" "}
                    <Link to="/signuppre"> SignUp </Link>
                  </div>
                )}
                {props.isAuthenticated && (
                  <div className={classes.siginsignup}>
                    {" "}
                    <div className={classes.whitebdr}>
                      <Link to="/profile"> Profile </Link>{" "}
                    </div>{" "}
                    <Link
                      to="/"
                      onClick={(e) => {
                        e.preventDefault();
                        props.logoutUser(
                          props.userInfo,
                          props.history.push("/signin")
                        );
                        google_analy("Clicked on Logout");
                      }}
                    >
                      Log Out
                    </Link>
                  </div>
                )}
              </li>
            </div>
          </ul>

          <Link
            onClick={() => {
              window.scroll({ left: 0, top: 0, behavior: "smooth" });
            }}
            to="/home"
          >
            <img
              src="https://github.com/KSHITIJ-2022/media/blob/master/images/ILU-light-new.png?raw=true"
              className={classes.mobileIcon}
              height="100%"
              alt="Kshitij"
            />
          </Link>
          <Backdrop
            open={isActive2}
            className={classes.backdrop}
            onClick={() => {
              toggleClass1();
            }}
          ></Backdrop>
          <ul
            className={
              isActive2
                ? `${classes.NavMenu1} ${classes.active}`
                : `${classes.NavMenu1}`
            }
          >
            <div className={classes.gradientdiv}>
              <div className={`${classes.navback}`}>
                <img
                  src="https://github.com/KSHITIJ-2022/media/blob/master/images/navbar/arrow.png?raw=true"
                  onClick={() => {
                    toggleClass1();
                  }}
                  alt="&gt;"
                  style={{ width: "50px", marginRight: "1.5rem" }}
                />
              </div>
              <li onClick={toggleClass1}>
                <Link
                  onClick={() => {
                    window.scroll({ left: 0, top: 0, behavior: "smooth" });
                  }}
                  to="/home"
                >
                  <img
                    src="https://github.com/KSHITIJ-2022/media/blob/master/images/ILU-light-new.png?raw=true"
                    height="150px"
                    alt="Kshitij"
                  />
                </Link>
              </li>
              <li className={classes.MobNavItem}>
                <div
                  onClick={(e) => e.preventDefault()}
                  className={
                    drop1 === true
                      ? `${classes.HamOuterStyle}`
                      : `${classes.HamOuter}`
                  }
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "column",
                    }}
                  >
                    {data.map((notification, index) => {
                      return (
                        <div
                          className={classes.notify_box}
                          onClick={() =>
                            window.open(notification.redirectUrl, "_blank")
                          }
                        >
                          <div className={classes.notify_subheader}>
                            <div className={classes.notify_header}>
                              {notification.title}
                            </div>
                            <div>{notification.message}</div>
                            <div className={classes.notify_time}>
                              {format(notification.createdAt)}
                            </div>
                          </div>
                          {/* <div className={classes.notify_imagebox}>
                            <img
                              className={classes.notify_image}
                              src={notification.imageUrl}
                            />
                          </div> */}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </li>
            </div>
          </ul>
          {/* {!isActive2 && (
            <div
              style={{ zIndex: '999' }}
              onClick={toggleClass1}
              // className={
              //   isActive2
              //     ? `${classes.hamburger} ${classes.active}`
              //     : `${classes.hamburger}`
              // }
              className={classes.bellicon}
            >
              
            </div>
          )} */}
          {!isActive && (
            <>
              <div>
                <div style={{ display: "flex" }}>
                  <BsFillBellFill
                    style={{
                      color: "white",
                      height: "40px",
                      width: "40px",
                      marginTop: "2.5px",
                      cursor: "pointer",
                      zIndex: "999",
                      marginRight: "22px",
                    }}
                    onClick={toggleClass1}
                  />
                  <div
                    style={{ zIndex: "998", marginTop: "9px" }}
                    onClick={toggleClass}
                    className={
                      isActive
                        ? `${classes.hamburger} ${classes.active}`
                        : `${classes.hamburger}`
                    }
                  >
                    <span className={classes.bar}></span>
                    <span className={classes.bar}></span>
                    <span className={classes.bar}></span>
                  </div>
                </div>
              </div>
            </>
          )}
        </nav>
      </header>
    </>
  );
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  userInfo: state.auth.user,
  auth: state.auth,
})

export default connect(mapStateToProps, { logoutUser })(withRouter(NavBar))