import React, { Component } from 'react'
import { withRouter, useHistory } from 'react-router-dom'
import { connect } from 'react-redux'

import classes from './signupMess.module.css'
import Vdo from '../../Components/vdoplayer/Vdo'
import isEmpty from '../../validation/isEmpty'
import PageWrapper from '../../Components/PageWrapper/PageWrapper'
const bgvdo =
  'https://github.com/KSHITIJ-2022/media/blob/master/Signin-Signup-Video/video/scanner-loop-final1.mp4?raw=true'
const bgvdo1 =
  'https://github.com/KSHITIJ-2022/media/blob/master/Signin-Signup-Video/video-mobile/loop-mobile-final.mp4?raw=true'
class presignupMess extends Component {
  constructor() {
    super()
    this.state = {
      errors: {},
      // redirectToProfile: this.redirectToProfile.bind(this),
      link: window.innerWidth < 996 ? bgvdo1 : bgvdo,
      isTrue: true,
    }
  }

  handleWindowSizeChange = () => {
    // this.setState({ isTrue: true })
    if (window.innerWidth < 996) {
      this.setState({
        link: bgvdo1,
      })
    } else {
      this.setState({
        link: bgvdo,
      })
    }
  }
  // handleVideoChange = () => {
  //   if (isEmpty(this.state.errors)) {
  //     this.setState({ isTrue: false })

  //     if (window.innerWidth < 996) {
  //       this.setState({ link: bgvdogreen1 })
  //     } else {
  //       this.setState({ link: bgvdogreen })
  //     }
  //   } else {
  //     this.setState({ isTrue: false })

  //     if (window.innerWidth < 996) {
  //       this.setState({ link: bgvdored1 })
  //     } else {
  //       this.setState({ link: bgvdored })
  //     }
  //   }
  // }
  componentDidMount() {
    window.addEventListener('resize', this.handleWindowSizeChange)
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange)
  }

  render() {
    return (
      <PageWrapper>
        {this.props.location.state?.email_id ? (
          <div className={classes.msgWrapper}>
            <div className={classes.regLine}>We have sent an email to</div>
            <div className={classes.signUpEmail}>
              {this.props.location.state.email_id}
            </div>

            <div className={classes.Idbox}>
              <span className={classes.idIntial}>
                Please verify your email to continue the Registration processs.
                <br />
                <span style={{ fontWeight: '600' }}>
                  (Check your spam if not found)
                </span>
              </span>
            </div>
            <div className={classes.regLine}>
              <div>To change the email </div>
              <div>
                <a className={classes.ClickHereLink} href='#/signuppre'>
                  Click here
                </a>
              </div>
            </div>
            <a href='#/home' style={{ textDecoration: 'none' }}>
              <div className={classes.explore}>Explore KTJ</div>
            </a>
          </div>
        ) : (
          this.props.history.goBack()
        )}
        <div
          style={{
            position: 'absolute',
            top: '0px',
            left: '0px',
            width: '100vw',
          }}
        >
          <Vdo
            name={this.state.link}
            loopCondition={this.state.isTrue}
            ended={this.handleWindowSizeChange}
          />
        </div>
      </PageWrapper>
    )
  }
}

// SignIn.propTypes = {
//   loginUser: PropTypes.func.isRequired,
//   auth: PropTypes.object.isRequired,
//   errors: PropTypes.object.isRequired,
// };

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
})

export default connect(mapStateToProps, {})(withRouter(presignupMess))
