import classes from './Schedule.module.css'
import React, { useState, useEffect } from 'react'
import Navigation from './Navigation'
import api from '../../api'
import Content from './Content'
import Data from './Data'
import Sponsors from './sponsors/Sponsors'


export default function () {
  console.log("Schedule")
  const [date, setDate] = useState(4)
  const [schedule, setSchedule] = useState([])
  const changeDate = (date) => {
    setDate(date)
  }
  useEffect(() => {
    api.get("/schedule").then((res) => {
      console.log("Schedule");
      console.log(res.data.schedule);
      setSchedule(res.data.schedule);
    });
  }, []);
  return (
    <div style={{ paddingTop: "80px" }}>
      <Sponsors />
      <Navigation date={date} setDate={changeDate} />
      <Content
        schedule={
          schedule
            ? schedule.filter((sch) => sch.date == "0" + date + "/03/2022")
            : null
        }
      />
    </div>
  );
}
