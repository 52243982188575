import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import classes from './signupMess.module.css'
import Navbar from '../../Components/Navbar/Navbar'
import Vdo from '../../Components/vdoplayer/Vdo'
import isEmpty from '../../validation/isEmpty'
import PageWrapper from '../../Components/PageWrapper/PageWrapper'
const bgvdo =
  'https://github.com/KSHITIJ-2022/media/blob/master/Signin-Signup-Video/video/scanner-loop-final1.mp4?raw=true'
const bgvdo1 =
  'https://github.com/KSHITIJ-2022/media/blob/master/Signin-Signup-Video/video-mobile/loop-mobile-final.mp4?raw=true'
const bgvdogreen1 =
  'https://github.com/KSHITIJ-2022/media/blob/master/Signin-Signup-Video/video-mobile/access-granted-final.mp4?raw=true'
const bgvdogreen =
  'https://github.com/KSHITIJ-2022/media/blob/master/Signin-Signup-Video/video/access-granted-final.mp4?raw=true'

const bgvdored =
  'https://github.com/KSHITIJ-2022/media/blob/master/Signin-Signup-Video/video/access-denied-final.mp4?raw=true'
const bgvdored1 =
  'https://github.com/KSHITIJ-2022/media/blob/master/Signin-Signup-Video/video-mobile/access-denied-final.mp4?raw=true'
class signupMess extends Component {
  constructor(props) {
    super()
    this.state = {
      errors: {},
      // redirectToProfile: this.redirectToProfile.bind(this),
      link: window.innerWidth < 996 ? bgvdo1 : bgvdo,
      isTrue: true,
    }
  }

  handleWindowSizeChange = () => {
    // this.setState({ isTrue: true })
    if (window.innerWidth < 996) {
      this.setState({
        link: bgvdo1,
      })
    } else {
      this.setState({
        link: bgvdo,
      })
    }
  }
  // handleVideoChange = () => {
  //   if (isEmpty(this.state.errors)) {
  //     this.setState({ isTrue: false })

  //     if (window.innerWidth < 996) {
  //       this.setState({ link: bgvdogreen1 })
  //     } else {
  //       this.setState({ link: bgvdogreen })
  //     }
  //   } else {
  //     this.setState({ isTrue: false })

  //     if (window.innerWidth < 996) {
  //       this.setState({ link: bgvdored1 })
  //     } else {
  //       this.setState({ link: bgvdored })
  //     }
  //   }
  // }
  componentDidMount() {
    window.addEventListener('resize', this.handleWindowSizeChange)
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange)
  }

  render() {
    return (
      <PageWrapper>
        {this.props.location.state?.ktjID ? (
          <>
            <div className={classes.msgWrapper}>
              <div className={classes.regLine}>
                You have been registered for
              </div>
              <div className={classes.ktjName}>
                KSHITIJ <span className={classes.year}>2022</span>.
              </div>
              <div className={classes.Idbox}>
                <span className={classes.idIntial}>Your KTJ-ID is </span>
                <span className={classes.ktjId}>
                  {this.props.location.state.ktjID}
                </span>
              </div>
              <div className={classes.note}>
                Please note it for future reference
              </div>
              <a href='#/signin' style={{ textDecoration: 'none' }}>
                <div className={classes.explore}>Explore KTJ</div>
              </a>
            </div>
            <div
              style={{
                position: 'absolute',
                top: '0px',
                left: '0px',
                width: '100vw',
              }}
            >
              <Vdo
                name={this.state.link}
                loopCondition={this.state.isTrue}
                ended={this.handleWindowSizeChange}
              />
            </div>
          </>
        ) : (
          this.props.history.goBack()
        )}
      </PageWrapper>
    )
  }
}

// SignIn.propTypes = {
//   loginUser: PropTypes.func.isRequired,
//   auth: PropTypes.object.isRequired,
//   errors: PropTypes.object.isRequired,
// };

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
})

export default connect(mapStateToProps, {})(withRouter(signupMess))
