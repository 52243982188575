import React, { useEffect, useState } from 'react'
// import Paper from '@material-ui/core/Paper'
// import Tab from '@material-ui/core/Tab'
// import Tabs from '@material-ui/core/Tabs'
import API from '../../../api'
import classes from './GamefestNew.module.css'
//import link B
import { Link } from 'react-router-dom'
const GamefestNew = () => {
  const [data, setData] = useState()

  useEffect(() => {
    console.log('useEffect')

    getGames()
  }, [])

  const getGames = () => {
    API.get('/games/getgames/')
      .then((res) => {
        setData(res.data.games)
        console.log(res.data.games)
      })
      .catch((err) => console.log(err))
  }

  return (
    <div className={classes.GamefestNewContainer}>
      <div className={classes.GamefestNewHeader}>
        <div className={classes.tab}>KRIEGSSPIEL</div>
      </div>
      {data?.map((item, index) => (
        <>
          <div className={classes.GamefestNewCard}>
            <div className={classes.ButtonStyle}>
              <img
                className={classes.ImgStyle}
                src={item.imageUrl}
                // src='https://cdn.talkesport.com/wp-content/uploads/csgo-breaks-record-for-highest-player-count-all-time.jpg.webp'
                alt=""
              />
              <Link to={`/game-register/${item._id}`}>
                <button className={classes.btngrp1}>Register</button>
              </Link>
            </div>

            <div className={classes.Gamedetails}>
              <div className={classes.GamedetailsHeader}>
                <h1 className={classes.TitleStyle}>{item.title}</h1>
                <div className={classes.GamedetailsHeaderRight}>
                  <h3
                    style={{
                      fontFamily: "NeueKabel",
                      fonWeight: "bold",
                      fontSize: "1rem",
                      color: "white",
                      fontStyle: "italic",
                    }}
                  >
                    Prize Money: INR {item.prize_money}
                  </h3>
                </div>
              </div>
              <h3 className={classes.description}>{item.content}</h3>
            </div>

            <div className={classes.buttons}>
              <div className={classes.contactus}>
                <div className={classes.contactitem}>Contact Us:</div>
                <div className={classes.subcontactitem}>
                  <i class="fas fa-user-alt"></i> &nbsp;
                  {/* {info.headObjectId?.username} */}
                  Abhinav Khare
                </div>
                <a
                  // href={'tel:' + info.headObjectId?.phone}
                  className={classes.subcontactitem}
                >
                  <i class="fas fa-phone-alt"></i>&nbsp;
                  {/* {info.headObjectId?.phone} */}
                  +91 9425112465
                </a>
                <a
                  // href={'mailto:' + info.headObjectId?.email}
                  className={classes.subcontactitem}
                >
                  <i class="far fa-envelope"></i>&nbsp;
                  {/* {info.headObjectId?.email} */}
                  abhinav.khare@ktj.in
                </a>
              </div>
              <Link to={`/game-register/${item._id}`}>
                <button className={classes.btngrp}>Register</button>
              </Link>
              {/* <button className={classes.btngrp}>Contact Details</button> */}
            </div>
          </div>
        </>
      ))}
    </div>
  );
}

export default GamefestNew
