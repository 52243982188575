import React from "react";
import Classes from "./button.module.css";
import ReactGa from "react-ga";
import {withRouter} from "react-router-dom";

class BackBtn extends React.Component {
  constructor(props) {
    super();
    this.goBack = this.goBack.bind(this);
  }
  goBack() {
    ReactGa.event({
      category: "Click",
      action: "Clicked on Back in ComingSoonpage",
    });
    this.props.history.goBack();
  }
  render() {
    const className =
      Classes.back_button +
      " " +
      {
        "left-bottom": Classes.left_bottom,
        "right-bottom": Classes.right_bottom,
        "left-top": Classes.left_top,
        "right-top": Classes.right_top,
      }[this.props.position];
    console.log(this.props.position);
    return (
      <button onClick={this.goBack} className={className}>
        Back
      </button>
    );
  }
}
export default withRouter(BackBtn);