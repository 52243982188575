import React, { Component } from "react";
import { Switch, Route, withRouter,Redirect } from "react-router-dom";
import { connect } from "react-redux";


import classes from "./signupMess.module.css";
// import Navbar from "../../Components/Navbar/Navbar";
import PresignupMessage from "./presignupMessage";
import PageWrapper from "../../Components/PageWrapper/PageWrapper";

class presignupMess extends Component {
  constructor(props) {


    super();
    this.state = {
      errors: {},
    };
  }

  render() {
    return (
      <PageWrapper>
              <PresignupMessage />
      </PageWrapper>
    );
  }
}

// SignIn.propTypes = {
//   loginUser: PropTypes.func.isRequired,
//   auth: PropTypes.object.isRequired,
//   errors: PropTypes.object.isRequired,
// };

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, {})(withRouter(presignupMess));
