import React, { useState, useEffect } from "react";
import classes from "./Schedule.module.css";
import bike from "./bike.png";
import api from "../../api";
import { DownOutlined } from "@ant-design/icons";
import Card from "./Card";
export default function (props) {
  window.addEventListener("wheel", (event) => {
    //move element with id bike as user scrolls
    const element = document.getElementById("bike");
    element.top = element.top + 230;
    // element.style.top = event.y + "px"
  });

  return (
    <div style={{ paddingTop: "40px", position: "relative" }}>
      <div className={classes.centerline}></div>
      <img src={bike} alt="" id="bike" className={classes.bike} />
      {props.schedule.map((item, index) => {
        return (
          <div className={index % 2 ? classes.card_right : classes.card_left}>
            <div
              className={index % 2 ? classes.width50_right : classes.width50}
            >
              <Card data={item} />
              <div className={classes.connectingline}></div>
              <div className={classes.outerCircle}>
                <div className={classes.middleCircleForEvent}></div>
              </div>
            </div>
            <div className={classes.time}>
              {parseInt(item.startingTime / 100) +
                ":" +
                item.startingTime.toString().slice(-2)}
              {item.endingTime ? (
                <>
                  <div className={classes.timeinnerdiv}>-</div>
                  <div className={classes.timeinnerdiv}>
                    {parseInt(item.endingTime / 100) +
                      ":" +
                      item.endingTime.toString().slice(-2)}
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        );
      })}
      {/* <div className={classes.card_left}><div className={classes.width50}><Card /> <div className={classes.connectingline}></div><div className={classes.outerCircle}><div className={classes.middleCircleForEvent}></div></div></div></div>
            <div className={classes.card_right}><div className={classes.width50_right}><Card /> <div className={classes.connectingline}></div><div className={classes.outerCircle}><div className={classes.middleCircleForEvent}></div></div></div></div>
            <div className={classes.card_left}><div className={classes.width50}><Card /> <div className={classes.connectingline}></div><div className={classes.outerCircle}><div className={classes.middleCircleForEvent}></div></div></div></div> */}
    </div>
  );
}
