import React, { Component } from 'react'
// import './signinform.css'
// import '../vdoplayer/Vdo'
// import '../../images/bgvdo.mp4'
import { Link } from 'react-router-dom'
import ReactGa from 'react-ga'

// import './SignUpPage.css'
import Classes from './Signin.module.css'

import Button from '@material-ui/core/Button'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { GoogleLogin } from 'react-google-login'
import { BsGoogle } from 'react-icons/bs'
import { BsFacebook } from 'react-icons/bs'
import { TailSpin } from 'react-loading-icons'
import { FaKey, FaEnvelope } from 'react-icons/fa'
import webstrip from "../../images/web strip-01.jpg";

class SignInform extends Component {
  constructor(props) {
    super();
    this.state = {
      errors: {},
    };
  }

  // Getting errors from backend and updating when new errors arrive
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.errors !== prevState.errors) {
      return { errors: nextProps.errors };
    } else return null;
  }

  render() {
    console.log(this.props.loading);
    let errors = this.props.errors;
    let errordisplayEmail = "";
    let errordisplayPassword = "";
    let classesEmail = "formInput ";
    let classesPassword = "formInput ";

    if (errors.emailsignin) {
      classesEmail = classesEmail + "red-text-field";
      errordisplayEmail = (
        <div className={Classes.error_message}>{errors.emailsignin} </div>
      );
    }
    if (errors.passwordsignin) {
      classesPassword = classesPassword + "red-text-field";
      errordisplayPassword = (
        <div className={Classes.error_message}>{errors.passwordsignin}</div>
      );
    }

    // Google login

    const googleSuccess = async (res) => {
      if (res) {
        this.props.googleLogin(res);
      }
    };

    const googleFailure = (err) => {
      // set Errro msg
      errors.emailsignin = "Error in Signing in with Google";
      console.log("google Signin was unsuccessful");
      console.log(err);
    };

    return (
      <div className="outer">
        <div className={Classes.signOuter}>
          <form
            onSubmit={(e) => {
              ReactGa.event({
                category: "Click",
                action: "Clicked on Sign In in Signinpage",
              });
              this.props.function(e);
            }}
            className="formOuter"
          >
            <Container>
              <Row>
                <Col className={Classes.signinform}>
                  <div className={Classes.container}>
                    <div className={Classes.inputCol}>
                      <FaEnvelope className={Classes.signinIcons} />
                      <input
                        className={Classes.input_field}
                        type="text"
                        id="email"
                        placeholder="Email"
                      />
                    </div>
                    {errordisplayEmail}
                    <div className={Classes.inputCol}>
                      <FaKey className={Classes.signinIcons} />
                      <input
                        className={Classes.input_field}
                        type="password"
                        id="password"
                        placeholder="Password"
                      />
                    </div>
                    {errordisplayPassword}
                  </div>

                  <br />

                  <div className={Classes.button_group_signin}>
                    <Button
                      className={Classes.button_group_signin_buttons}
                      variant="contained"
                      color="primary"
                      style={{ fontFamily: "NeueKabel" }}
                      onClick={this.props.clicked}
                    >
                      {this.props.loading ? (
                        <div
                          style={{
                            marginRight: "9px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <TailSpin width="20" height="20" />
                        </div>
                      ) : (
                        ""
                      )}
                      <input
                        type="submit"
                        style={{
                          border: "none",
                          color: "inherit",
                          background: "none",
                          padding: "0",
                          font: "inherit",
                          cursor: "pointer",
                          outline: "inherit",
                        }}
                        value="Sign in"
                      />
                    </Button>

                    <Link
                      onClick={() => {
                        ReactGa.event({
                          category: "Click",
                          action: "Clicked on Forget Password in Signinpage",
                        });
                      }}
                      to="/resetpassword"
                    >
                      <Button
                        className={Classes.button_group_signin_buttons}
                        variant="contained"
                        color="primary"
                        style={{
                          width: "100%",
                          textTransform: "none",
                          fontFamily: "NeueKabel",
                        }}
                      >
                        Forgot Password
                      </Button>
                    </Link>
                  </div>
                  <div className={Classes.googleButtonPara}>
                    <GoogleLogin
                      clientId="641600505779-28br6dkj36bocf5095uo2nd4nmoob6te.apps.googleusercontent.com"
                      render={(renderProps) => (
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={renderProps.onClick}
                          disabled={renderProps.disabled}
                          className={Classes.button}
                          style={{
                            textTransform: "none",
                            fontFamily: "NeueKabel",
                          }}
                          onclick={this.props.clicked}
                        >
                          <BsGoogle style={{ marginRight: "5px" }} /> Sign In
                          with Google
                        </Button>
                      )}
                      onSuccess={googleSuccess}
                      onFailure={googleFailure}
                      cookiePolicy="single_host_origin"
                    />

                    <p className={Classes.alreadySignin}>
                      Don't have an account?&nbsp;
                      <Link to="/signuppre">
                        <div className={Classes.aeeE}>Sign up</div>
                      </Link>
                    </p>
                  </div>
                  <Link to="/sponsors">
                    <img src={webstrip} style={{ width: "100%" }} />
                  </Link>
                </Col>
              </Row>
            </Container>
          </form>
        </div>
      </div>
    );
  }
}

export default SignInform
