import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import './signup.module.css'
import Vdo from '../../Components/vdoplayer/Vdo'
import isEmpty from '../../validation/isEmpty'
import Form from '../../Components/Form/signupformpre'
import Navbar from '../../Components/Navbar/Navbar'
import { preregisterUser, googleSignIn } from '../../actions/authActions.js'
import PageWrapper from '../../Components/PageWrapper/PageWrapper'
const bgvdo =
  'https://github.com/KSHITIJ-2022/media/blob/master/Signin-Signup-Video/video/scanner-loop-final1.mp4?raw=true'
const bgvdo1 =
  'https://github.com/KSHITIJ-2022/media/blob/master/Signin-Signup-Video/video-mobile/loop-mobile-final.mp4?raw=true'
// const bgvdogreen1 =
//   'https://github.com/KSHITIJ-2022/media/blob/master/Signin-Signup-Video/video-mobile/access-granted-final.mp4?raw=true'
// const bgvdogreen =
//   'https://github.com/KSHITIJ-2022/media/blob/master/Signin-Signup-Video/video/access-granted-final.mp4?raw=true'

// const bgvdored =
//   'https://github.com/KSHITIJ-2022/media/blob/master/Signin-Signup-Video/video/access-denied-final.mp4?raw=true'
// const bgvdored1 =
//   'https://github.com/KSHITIJ-2022/media/blob/master/Signin-Signup-Video/video-mobile/access-denied-final.mp4?raw=true'
class signuppre extends React.Component {
  constructor() {
    super()
    this.state = {
      errors: {},
      redirectToProfile: this.redirectToProfile.bind(this),
      link: window.innerWidth < 996 ? bgvdo1 : bgvdo,
      isTrue: true,
    }
  }
  componentDidMount() {
    window.addEventListener('resize', this.handleWindowSizeChange)
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange)
  }

  handleWindowSizeChange = () => {
    // this.setState({ isTrue: true })
    if (window.innerWidth < 996) {
      this.setState({
        link: bgvdo1,
      })
    } else {
      this.setState({
        link: bgvdo,
      })
    }
  }
  // handleVideoChange = () => {
  //   if (isEmpty(this.state.errors)) {
  //     this.setState({ isTrue: false })

  //     if (window.innerWidth < 996) {
  //       this.setState({ link: bgvdogreen1 })
  //     } else {
  //       this.setState({ link: bgvdogreen })
  //     }
  //   } else {
  //     this.setState({ isTrue: false })

  //     if (window.innerWidth < 996) {
  //       this.setState({ link: bgvdored1 })
  //     } else {
  //       this.setState({ link: bgvdored })
  //     }
  //   }
  // }
  redirectToProfile = () => {
    this.props.history.push('/profile')
  }

  // Getting errors from backend and updating when new errors arrive
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.auth.isAuthenticated) {
      return {
        model: prevState.redirectToProfile(nextProps.model),
      }
    }
    if (nextProps.errors !== prevState.errors) {
      return { errors: nextProps.errors }
    } else return null
  }

  callAPI = (e) => {
    e.preventDefault()
    const newUser = {
      email: document.getElementById('email').value.trim().toLowerCase(),
    }
    this.props.preregisterUser(newUser, this.props.history)
  }

  callgoogleAPI = (res) => {
    const newUser = {
      email: res?.profileObj.email,
      token: res?.tokenId,
    }

    this.props.googleSignIn(newUser, this.props.history)
  }

  render() {
    return (
      <PageWrapper>
        <Vdo
          name={this.state.link}
          loopCondition={this.state.isTrue}
          ended={this.handleWindowSizeChange}
        />
        <Form
          errors={this.state.errors}
          function={this.callAPI}
          googleAuth={this.callgoogleAPI}
          loading={this.props.loading}
          // clicked={this.handleVideoChange}
        />
      </PageWrapper>
    )
  }
}

// Just checking the type of the props component
signuppre.propTypes = {
  googleSignIn: PropTypes.func.isRequired,
  preregisterUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  loading: state.auth.loading,
})

export default connect(mapStateToProps, { preregisterUser, googleSignIn })(
  withRouter(signuppre)
)
