import React from "react";
import { Link } from "react-router-dom";
import ReactGa from "react-ga";
import { useHistory } from "react-router-dom";
// CSS import
import "./index.css";

function Card(props) {
  const history = useHistory();
  const closefooter = () => {
    ReactGa.event({
      category: "Click",
      action: "Clicked Explore button of " + props.title,
    });
    window.scroll({ left: 0, top: 0, behavior: "smooth" });
  };
  let redirecturl = "/event/" + props.id;
  // "/event/" + props.title.trim().toLowerCase().replace(" ", "-");
  return (
    <div>
      {/* http://localhost:5000/ */}
      <div
        id="evt_card"
        className="card_desk"
        onClick={() => history.push({ pathname: props.redirectPage })}
      >
        <img src={`${props.imageUrl}`} id="background_img" />
        <div id="card_details">
          <div id="margins">
            <div
              id="card_content"
              dangerouslySetInnerHTML={{ __html: props.content }}
            ></div>
          </div>
          <Link onClick={closefooter} to={props.redirectPage}>
            <button id="firstbtn">Click to know more</button>
          </Link>
          {/* <Link onClick={closefooter} to={props.redirectPage}>
                        <button className="sndbtn" id="firstbtn">Explore Now</button>
                    </Link> */}
        </div>
      </div>
      <h1
        className="Title_comp"
        onClick={() => history.push({ pathname: props.redirectPage })}
      >
        {props.title}
      </h1>
      <div id="card_content" className="card_mobile">
        <img src={`${props.imageUrl}`} id="background_img2" />
        <div className="lower_part_card">
          <div id="margins">
            <span className="title_mobile">{props.title}</span>
            <h6 id="prize_h6">
              <i>Prize Money : INR {props.prize}</i>
            </h6>
            <div>Description:</div>
            <div
              className="des"
              dangerouslySetInnerHTML={{ __html: props.content }}
            ></div>
          </div>
          <Link onClick={closefooter} to={props.redirectPage}>
            <button id="firstbtn">Click to know more</button>
          </Link>
          {/* <Link onClick={closefooter} to={props.redirectPage}>
                        <button className="sndbtn" id="firstbtn">Explore Now</button>
                    </Link> */}
        </div>
      </div>
    </div>
  );
}

export default Card;