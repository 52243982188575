import React from 'react'
import classes from './Schedule.module.css'
export default function (props) {
    return (
        <div className={classes.navouter}>
            <div className={`${classes.onenav} ${props.date == 4 ? classes.active : ""}`} onClick={() => props.setDate(4)}>4TH</div>
            <div className={`${classes.onenav} ${props.date == 5 ? classes.active : ""}`} onClick={() => props.setDate(5)}>5TH</div>
            <div className={`${classes.onenav} ${props.date == 6 ? classes.active : ""}`} onClick={() => props.setDate(6)}>6TH</div>
        </div>
    )
}
