export const GET_ERRORS = "GET_ERRORS";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const CREATE_ISSUES_SUCCESS = "CREATE_ISSUES_SUCCESS";
export const CREATE_ISSUES_START = "CREATE_ISSUES_START";
export const CREATE_ISSUES_FAILED = "CREATE_ISSUES_FAILED";
export const FETCH_ISSUES_SUCCESS = "FETCH_ISSUES_SUCCESS";
export const FETCH_ISSUES_START = "FETCH_ISSUES_START";
export const FETCH_ISSUES_FAILED = "FETCH_ISSUES_FAILED";
export const LOG_IN_START='LOG_IN_START'
export const LOG_IN_SUCCESS='LOG_IN_SUCCESS'
export const LOG_IN_FAILURE='LOG_IN_FAILURE'
export const PRE_SIGN_UP_START='PRE_SIGN_UP_START'
export const PRE_SIGN_UP_SUCCESS='PRE_SIGN_UP_SUCCESS'
export const PRE_SIGN_UP_FAILURE='PRE_SIGN_UP_FAILURE'
export const SIGN_UP_START='SIGN_UP_START'
export const SIGN_UP_SUCCESS='SIGN_UP_SUCCESS'
export const SIGN_UP_FAILURE='SIGN_UP_FAILURE'


