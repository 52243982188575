// Packages import
import React, { Component } from 'react'
import { Switch, Route, withRouter, Redirect } from 'react-router-dom'
import { CustomPreloader } from 'react-preloaders'
import { connect } from 'react-redux'
import ReactGa from 'react-ga'
import 'antd/dist/antd.css'
import Loadable from 'react-loadable'
// Loader Import
import Loader from './Components/Loader'
import ScrollToTop from './Components/ScrollToTop/ScrollToTop'
import store from './store'
import { fetchIssues } from './actions/issueActions'
import competition from './Components/Events/competition/competitions'
import EventInfo from './Components/Events/event_page/eventinfo/index'
// import GameInfo from './Components/Events/gamefest/game_page/gameinfo'
// Desktop Route Imports
import Coming from './Components/Coming_soon/Coming'
import Footer from './Components/Footer/Footer'
import ForgotPassword from './Components/Form/forgotpassword'
import SignIn from './pages/signIn/signIn'
import Signup from './pages/signup/signup'
import Signuppre from './pages/signuppre/signuppre'
import SignupMess from './pages/signupMess/signupMess'
import PreSignupMess from './pages/signupMess/presignupMess'
import Registration from './Components/Events/event_page/registration'
import WorkshopRoutes from './Components/Activities/Workshop/WorkshopRoutes'
import Schedule from './pages/schedule/Schedule'
//IMPORT GAMEFEST NEW
import GamefestNew from './Components/Events/gamefest/GamefestNew'

import GameRegistration from './Components/Events/gamefest/game_page/gameregistration/index'
import { userAuth } from './actions/authActions'

// Function Imports
import API from './api'
import { setCurrentUser } from './actions/authActions'
import Navbar from './Components/Navbar/Navbar'
import temp from './Components/Events/event_page/event_info_bclub/index.js'
// Media Imports

// import preload_gif from './images/preloader_web_compressed.mp4';

// import preloader_mobile from './images/preloader_mobile.webm';

const useEffect = () => {
  const getCsrfToken = async () => {
    ReactGa.initialize('UA-186285820-1')
    const { data } = await API.get('/csrf-token')
    API.defaults.headers.common['x-csrf-token'] = data.csrfToken
  }
  getCsrfToken()
}

const breakingpoint = 996

// Loading Screen for CodeSplit
const Loading = () => (
  <div style={{ width: '100%', height: '100vh', backgroundColor: '#251F5A' }}>
    <Loader />
  </div>
)

// Desktop CodeSplit Starts
const AsyncHome = Loadable({
  loader: () => import('./Containers/Homepage/Homepage'),
  loading: Loading,
})
const AsyncAdminPanel = Loadable({
  loader: () => import('./Containers/MainLayout'),
  loading: Loading,
})
const AsyncProfile = Loadable({
  loader: () => import('./Components/Profile/Profile'),
  loading: Loading,
})
const AsyncContactUs = Loadable({
  loader: () => import('./Components/Contactus/ContactRoutes'),
  loading: Loading,
})
const AsyncSponsors = Loadable({
  loader: () => import('./pages/Sponsors/Sponsors'),
  loading: Loading,
})
const AsyncInnovative = Loadable({
  loader: () => import('./pages/Innovative Corner/Innovatives'),
  loading: Loading,
})
const AsyncActivities = Loadable({
  loader: () => import('./pages/activities/Activities_routes'),
  loading: Loading,
})
class App extends Component {
  state = {
    windowwidth: window.innerWidth,
  }

  componentDidUpdate() {
    ReactGa.pageview(this.props.location.pathname)
    useEffect()
  }
  componentDidMount() {
    window.addEventListener('resize', this.handleWindowSizeChange)
    useEffect()

    store.dispatch(userAuth())

    if (localStorage.ktjUserLoginData) {
      let userData = JSON.parse(localStorage.ktjUserLoginData)
      store.dispatch(setCurrentUser(userData))
      store.dispatch(fetchIssues(userData._id))
    }
    ReactGa.pageview(this.props.location.pathname)
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange)
  }
  handleWindowSizeChange = () => {
    if (
      (window.innerWidth - breakingpoint) *
        (this.state.windowwidth - breakingpoint) <
      0
    )
      this.setState({ windowwidth: window.innerWidth })
  }
  render() {
    this.useEffect
    const { auth } = this.props
    const { user } = auth
    // if (this.state.windowwidth > breakingpoint)

    const preLoader =
      window.innerWidth > 768
        ? 'https://github.com/KSHITIJ-2022/media/blob/master/Videos/preloader_web_compressed.mp4?raw=true'
        : 'https://github.com/KSHITIJ-2022/media/blob/master/Videos/preloader%20compressed.mp4?raw=true'

    return (
      <React.Fragment>
        <ScrollToTop />
        <Navbar />
        <div className='App' id='App'>
          <Switch>
            <Route exact path='/' component={AsyncHome} />
            <Route exact path='/home/:id' component={AsyncHome} />
            <Route exact path='/schedule' component={Schedule} />
            <Route path='/activities' component={AsyncActivities} />
            <Route exact path='/coming' component={Coming} />
            <Route path='/contactus' component={AsyncContactUs} />
            <Route path='/events/competitions' component={competition} />
            <Route path='/events/gamefest' component={GamefestNew} />
            <Route path='/event/icc' component={temp} />
            <Route path='/event/:id' component={EventInfo} />
            <Route path='/register/:id' component={Registration} />

            <Route path='/game-register/:id' component={GameRegistration} />
            {/* <Route path='/gameinfo/:id' component={GameInfo} /> */}

            <Route exact path='/home' component={AsyncHome} />
            <Route path='/home/:frame' component={AsyncHome} />
            <Route exact path='/profile' component={AsyncProfile} />
            <Route exact path='/resetpassword' component={ForgotPassword} />
            <Route exact path='/signin' component={SignIn} />
            <Route exact path='/signup' component={Signup} />
            <Route exact path='/signuppre' component={Signuppre} />
            <Route exact path='/signupmessage' component={SignupMess} />
            <Route exact path='/presignupmessage' component={PreSignupMess} />
            <Route exact path='/sponsors' component={AsyncSponsors} />
            <Route exact path='/innovative' component={AsyncInnovative} />
            <Route path='/workshop' component={WorkshopRoutes} />
            <Route path='/admin-panelktj2022' component={AsyncAdminPanel} />
          </Switch>
          {/* <Issues issues={this.props.issues.issues} /> */}
          <Footer />
        </div>
        <CustomPreloader background='#000000'>
          <video
            alt='loading...'
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            loop
            autoPlay
            muted
            controls={false}
          >
            <source src={preLoader} type='video/mp4' />
          </video>
        </CustomPreloader>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    issues: state.issues,
  }
}

export default connect(mapStateToProps, null)(withRouter(App))
