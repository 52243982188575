import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import ReactGA from 'react-ga'
import './signIn.module.css'
import SignInform from '../../Components/Form/signinform'
import isEmpty from '../../validation/isEmpty'
import Vdo from '../../Components/vdoplayer/Vdo'

import Navbar from '../../Components/Navbar/Navbar'

import { loginUser, googleLogin } from '../../actions/authActions'
import PageWrapper from '../../Components/PageWrapper/PageWrapper'
import { GiTruce } from 'react-icons/gi'

const bgvdo =
  'https://github.com/KSHITIJ-2022/media/blob/master/Signin-Signup-Video/video/scanner-loop-final1.mp4?raw=true'
const bgvdo1 =
  'https://github.com/KSHITIJ-2022/media/blob/master/Signin-Signup-Video/video-mobile/loop-mobile-final.mp4?raw=true'
// const bgvdogreen1 =
//   'https://github.com/KSHITIJ-2022/media/blob/master/Signin-Signup-Video/video-mobile/access-granted-final.mp4?raw=true'
// const bgvdogreen =
//   'https://github.com/KSHITIJ-2022/media/blob/master/Signin-Signup-Video/video/access-granted-final.mp4?raw=true'

// const bgvdored =
//   'https://github.com/KSHITIJ-2022/media/blob/master/Signin-Signup-Video/video/access-denied-final.mp4?raw=true'
// const bgvdored1 =
//   'https://github.com/KSHITIJ-2022/media/blob/master/Signin-Signup-Video/video-mobile/access-denied-final.mp4?raw=true'
class SignIn extends React.Component {
  constructor(props) {
    super()
    this.state = {
      errors: {},
      redirectToProfile: this.redirectToProfile.bind(this),
      link: window.innerWidth < 996 ? bgvdo1 : bgvdo,
      isTrue: true,
    }
  }

  handleWindowSizeChange = () => {
    this.setState({ isTrue: true })
    if (window.innerWidth < 996) {
      this.setState({
        link: bgvdo1,
      })
    } else {
      this.setState({
        link: bgvdo,
      })
    }
  }

  // handleVideoChange = () => {
  //   if (isEmpty(this.state.errors)) {
  //     this.setState({ isTrue: false })
  //     if (window.innerWidth < 996) {
  //       this.setState({ link: bgvdogreen1 })
  //     } else {
  //       this.setState({ link: bgvdogreen })
  //     }
  //   } else {
  //     this.setState({ isTrue: false })
  //     if (window.innerWidth < 996) {
  //       this.setState({ link: bgvdored1 })
  //     } else {
  //       this.setState({ link: bgvdored })
  //     }
  //   }
  // }

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowSizeChange)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange)
  }

  redirectToProfile = () => {
    if (localStorage.getItem('PreviousPath')) {
      this.props.history.push(localStorage.getItem('PreviousPath'))
      //clear local storage
      localStorage.removeItem('PreviousPath')
    } else {
      this.props.history.push('/profile')
    }
  }

  // Getting errors from backend and updating when new errors arrive
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.auth.isAuthenticated) {
      return {
        model: prevState.redirectToProfile(nextProps.model),
      }
    }
    if (nextProps.errors !== prevState.errors) {
      return { errors: nextProps.errors }
    } else return null
  }

  callAPI = (e) => {
    e.preventDefault()
    const email = document.getElementById('email').value.trim()
    const password = document.getElementById('password').value.trim()
    const userData = { email, password }
    this.props.loginUser(userData)
    ReactGA.event({
      category: 'Click',
      action: 'Clicked Sign In button on Signin page',
    })
  }

  callGoogleLogin = (res) => {
    const email = res.profileObj.email
    const user = { email, token: res.tokenId }
    this.props.googleLogin(user, this.props.history)
    ReactGA.event({
      category: 'Click',
      action: 'Clicked Sign In through google button on Signin page',
    })
  }

  render() {
    return (
      <PageWrapper>
        <SignInform
          errors={this.state.errors}
          function={this.callAPI}
          googleLogin={this.callGoogleLogin}
          loading={this.props.loading}
          // clicked={this.handleVideoChange}
        />

        <Vdo
          name={this.state.link}
          loopCondition={this.state.isTrue}
          ended={this.handleWindowSizeChange}
        />
      </PageWrapper>
    )
  }
}

SignIn.propTypes = {
  loginUser: PropTypes.func.isRequired,
  googleLogin: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => {
  // console.log(state)
  return {
    auth: state.auth,
    errors: state.errors,
    loading: state.auth.loading,
  }
}

export default connect(mapStateToProps, { loginUser, googleLogin })(
  withRouter(SignIn)
)
