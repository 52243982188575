import React, { Component } from 'react'
// import "./signupform.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPhoneAlt,
  faGraduationCap,
  faCity,
  faHotel,
} from '@fortawesome/free-solid-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { faPeopleArrows } from '@fortawesome/free-solid-svg-icons'
import { faBook } from '@fortawesome/free-solid-svg-icons'
import { faPen } from '@fortawesome/free-solid-svg-icons'
import { faLock } from '@fortawesome/free-solid-svg-icons'
import ReactGa from 'react-ga'

import { GoogleLogin } from 'react-google-login'

import Classes from './PreSignUpPage.module.css'
import { Link } from 'react-router-dom'
// import './PreSignUpPage.module.css'
import {
  FaPenNib,
  FaBuilding,
  FaCity,
  FaMapMarkerAlt,
  FaEnvelope,
} from 'react-icons/fa'
import { FaUserAlt } from 'react-icons/fa'
import { BsGenderAmbiguous } from 'react-icons/bs'
import { FiPhoneCall } from 'react-icons/fi'
import { GiGraduateCap } from 'react-icons/gi'
import { BsGoogle } from 'react-icons/bs'
import Button from '@material-ui/core/Button'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { TailSpin } from 'react-loading-icons'
import webstrip from "../../images/web strip-01.jpg";

class signupformpre extends Component {
  constructor(props) {
    super();
    this.state = {
      errors: {},
    };
  }

  // Getting errors from backend and updating when new errors arrive
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.errors !== prevState.errors) {
      return { errors: nextProps.errors };
    } else return null;
  }

  render() {
    let errors = this.props.errors;

    let errordisplayEmail = "";
    let classesEmail = "formInput ";
    console.log(errors);
    if (errors.emailpresignup) {
      classesEmail = classesEmail + "red-text-field";
      errordisplayEmail = (
        <div className="error-message" style={{ color: "red" }}>
          {errors.emailpresignup}
        </div>
      );
    } else {
      errordisplayEmail = (
        <div className="error-message" style={{ color: "red" }}>
          <br />
        </div>
      );
    }

    // Google OAuth

    const googleSuccess = async (res) => {
      if (res) {
        this.props.googleAuth(res);
      }
    };
    const BackToSignIn = () => {};
    const googleFailure = (err) => {
      // set Errro msg
      errors.emailpresignup = "Error in Signing up with Google";
      console.log("google Signin was unsuccessful");
      console.log(err);
    };

    return (
      <div className={Classes.form}>
        <form
          id="form"
          onSubmit={(e) => {
            ReactGa.event({
              category: "Click",
              action: "Clicked on Sign Up in Signuppage",
            });
            this.props.function(e);
          }}
        >
          <Container>
            <Row>
              <Col className={Classes.presignupform}>
                <div className={Classes.container}>
                  <div className={Classes.input_icons}>
                    <div className={Classes.inputRow}>
                      <FaEnvelope className={Classes.signupPage_icons} />
                      <input
                        className={[
                          Classes.input_field,
                          Classes.with_icon,
                        ].join(" ")}
                        id="email"
                        type="text"
                        placeholder="Email"
                      />
                    </div>
                    {errordisplayEmail}
                  </div>
                </div>
                <div className={Classes.googleButtonPara}>
                  <Button
                    ClassName={Classes.button}
                    variant="contained"
                    color="primary"
                    style={{
                      fontFamily: "NeueKabel",
                    }}
                    onClick={this.props.clicked}
                  >
                    <button
                      type="submit"
                      style={{
                        border: "none",
                        color: "inherit",
                        background: "none",
                        padding: "0",
                        font: "inherit",
                        cursor: "pointer",
                        outline: "inherit",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {this.props.loading ? (
                        <span style={{ marginRight: "9px" }}>
                          <TailSpin width="20" height="20" />
                        </span>
                      ) : (
                        ""
                      )}
                      <span style={{ fontFamily: "NeueKabel" }}>Sign up</span>
                    </button>
                  </Button>

                  <GoogleLogin
                    clientId="641600505779-28br6dkj36bocf5095uo2nd4nmoob6te.apps.googleusercontent.com"
                    render={(renderProps) => (
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={renderProps.onClick}
                        disabled={renderProps.disabled}
                        className={Classes.button}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "centre",
                          textTransform: "none",
                          fontFamily: "NeueKabel",
                        }}
                      >
                        <BsGoogle style={{ marginRight: "5px" }} />
                        Sign Up with Google
                      </Button>
                    )}
                    onSuccess={googleSuccess}
                    onFailure={googleFailure}
                    cookiePolicy="single_host_origin"
                  />
                  <p className={Classes.alreadySignin}>
                    Already have an account?&nbsp;
                    <Link to="/signin">
                      <div className={Classes.aeeE}>Sign in</div>
                    </Link>
                  </p>
                </div>
                <Link to="/sponsors">
                  <img src={webstrip} style={{ width: "100%" }} />
                </Link>
              </Col>
            </Row>
          </Container>
        </form>
      </div>
    );
  }
}

export default signupformpre
