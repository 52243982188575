import React, { Component,useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

import classes from './Input.module.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes, faPlus } from '@fortawesome/free-solid-svg-icons'


class Input extends Component {
  constructor(props) {
    super();
    this.state = {
      errors: {},
      alert: { show: false, msg: "", type: "" },
      captainId: "",
      usernameCaptain: "",
    };
  }
  setAlert(newAlert) {
    alert: newAlert;
  }

  //
  // Getting errors from backend and updating when new errors arrive
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.teamList !== prevState.teamList) {
      return { teamList: nextProps.teamList };
    } else if (nextProps.error !== prevState.error) {
      return { error: nextProps.error };
    } else return null;
  }
  render() {
    return (
      <div className={classes.uldiv}>
        <li key={-1}>
          <label className={classes.ktjIDLabel}>Team Name: </label>
          <input
            type="text"
            name="teamName"
            placeholder="Team Name"
            id="teamNameInput"
            value={this.props.teamName}
            className={classes.ktjIDField}
            required
            disabled={!this.props.editing}
          />
        </li>
        {this.props.teamList.map((val, index, arr) => (
          <li key={index}>
            <form className={classes.box}>
              {index === 0 ? (
                <label className={classes.ktjIDLabel}>Team Captain: </label>
              ) : (
                <label className={classes.ktjIDLabel}>
                  Team Member {index + 1}:
                </label>
              )}
              <input
                type="text"
                name="ktjIDField"
                placeholder="KTJ-ID"
                id={`eventKTJID${index}`}
                value={val.eventKTJID}
                className={classes.ktjIDField}
                disabled={index == 0 || val.verified}
                required
              />

              <input
                type="text"
                name="ignField"
                placeholder={
                  {
                    Valorant: "Riot-ID",
                    "Call of Duty: Mobile": "In-Game Name",
                    "Battlegrounds Mobile India": "In-Game Name",
                  }[this.props.title]
                }
                id={`eventign${index}`}
                value={val.ign}
                className={classes.ktjIDField}
                disabled={index != 0 ? val.verified : !this.props.editing}
                required
              />
              {this.props.title == "Valorant" && "#"}
              <input
                type="text"
                name="in_game_idField"
                placeholder={
                  {
                    Valorant: "Tagline",
                    "Call of Duty: Mobile": "UID",
                    "Battlegrounds Mobile India": "BGMI ID",
                  }[this.props.title]
                }
                id={`eventin_game_id${index}`}
                value={val.in_game_id}
                className={classes.ktjIDField}
                disabled={index != 0 ? val.verified : !this.props.editing}
                required
              />

              {!this.props.teamList[index].verified ? (
                <div className={classes.verifyButtonOuter}>
                  <div className={classes.verifyButton}>
                    <button
                      type="submit"
                      className={classes.verifyButtonInner}
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.findMember(val.eventKTJID, index);
                      }}
                    >
                      Verify
                    </button>
                  </div>
                </div>
              ) : (
                <input
                  type="text"
                  name="namefield"
                  className={classes.ktjIDField}
                  placeholder="Name"
                  value={val.Name}
                  disabled
                />
              )}
              {this.props.editing &&
              !index == 0 &&
              // (this.props.teamList.filter((team) => {
              //   return team.verified;
              // }).length > this.props.minCount ||
              //   (!this.props.teamList[index].verified &&
              //     index + 1 > this.props.minCount)) &&
              this.props.isCaptain ? (
                <div
                  className={classes.deleteButton}
                  onClick={() => this.props.deleteMember(index)}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </div>
              ) : null}
            </form>

            {/* {index < this.props.maxCount - 1 && index + 1 == arr.length ? ( */}

            {/* ) : null} */}
          </li>
        ))}
        {this.props.editing &&
        this.props.teamList.length < this.props.maxCount &&
        this.props.isCaptain ? (
          <button
            className={classes.addMember}
            onClick={() => {
              this.props.addMember();
            }}
            type="button"
          >
            <FontAwesomeIcon icon={faPlus} style={{ fontSize: "60%" }} />
            &nbsp;Add a Teammate
          </button>
        ) : null}
      </div>
    );
  }
}

Input.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
})

export default connect(mapStateToProps, {})(withRouter(Input))
