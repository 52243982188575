import React from 'react'
import classes from "./Card.module.css";

export default function Card(props) {
    return (
      <div
        className={classes.main}
        style={{
          backgroundImage:
            "linear-gradient(to bottom right,rgba(255, 255, 255, 0.3),transparent)",
          backgroundColor: "rgba(1, 4, 54, 1)",
        }}
      >
        <div className={classes.imgdiv}>
          <img src={props.data.imageUrl} alt="" className={classes.img1} />
          <img src={props.data.sponsImageUrl} alt="" className={classes.img2} />
        </div>
        <div className={classes.info_div}>
          <div className={classes.title}>{props.data.type}</div>
          <div className={classes.name}>{props.data.title}</div>
          <p className={classes.info}>{props.data.description}</p>
        </div>
        {props.data.joiningLink && props.data.joiningLinkText && (
          <a
            className={classes.btn}
            href={props.data.joiningLink}
            target="_blank"
          >
            <button className={classes.button_}>
              {props.data.joiningLinkText}
            </button>
          </a>
        )}
      </div>
    );
}
